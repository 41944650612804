<template>
    <div class="content">
        <div class="main">
            <div class="left">
                <div class="search">
                    <div class="row">
                        <div class="iconfont icon-sousuo font-color-wirte"></div>
                        <div class="placeholder">输入影片的<span class="font-color-wirte">首字母</span>或<span
                                class="font-color-wirte">全拼</span></div>
                        <!-- <input type="text" class="input" v-model="input" :disabled="false"> -->
                    </div>
                </div>

                <div class="clear font-color-wirte">
                    <div class="center" @click="tapClear">
                        <div class="iconfont icon-ziyuanxhdpi"></div>
                        <div class="name">清除</div>
                    </div>

                    <div class="center">
                        <div class="iconfont icon-fanhui"></div>
                        <div class="name">退格</div>
                    </div>
                </div>

                <div class="keypad font-color-wirte">
                    <!-- 全键盘 -->
                    <div class="keypadList" v-for="key in keys" @click="keyPress(key)" :key="key">{{ key }}</div>
                </div>

                 <!-- t9键盘 -->
                 <!-- <div class="t9keypad font-color-wirte">
                        <div class="item" v-for="item in t9Keys" @click="t9KeyPress(item.key)" :key="item.key">
                            {{ item.key }}
                            <div class="position"></div>
                            <div class="item">
                                {{ item.en.join('') }}</div>
                        </div>
                    </div> -->


                <div class="btn font-color-wirte">
                    <div class="fingerboard">
                        全键盘
                    </div>

                    <div class="fingerboard">
                        T9键盘
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="recommend" v-for="it in [1,2]">
                    <div class="title">
                        热搜榜
                    </div>
                  <div class="center">
                    <div class="recommend-list" v-for="item in [1,2,3,4,5,6]">
                        <div class="span"></div>
                        <div class="name font-color-wirte">茶文化</div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            keys: [
                "A", "B", "C", "D", "E", "F", "G", "H", "I", "J",
                "K", "L", "M", "N", "O", "P", "Q", "R", "S",
                "T", "U", "V", "W", "X", "Y", "Z",
                "1", "2", "3", "4", "5", "6", "7", "8", "9", "0",
            ],

            t9Keys: [
                { key: "1", en: ["0", "1", " "] },
                { key: "2", en: ["A", "B", "C"] },
                { key: "3", en: ["D", "E", "F"] },
                { key: "4", en: ["G", "H", "I"] },
                { key: "5", en: ["J", "K", "L"] },
                { key: "6", en: ["M", "N", "O"] },
                { key: "7", en: ["P", "Q", "R", "S"] },
                { key: "8", en: ["T", "U", "V"] },
                { key: "9", en: ["W", "X", "Y", "Z"] },
                // {key: "*", en:[]},
                // {key: "0", en:[]},
                // {key: "#", en:[]},
            ],
            input: "",
            keyPressedBuffer: "",
            keyPressCount: 0,
            keyPressTimer: null,
        }
    },

    created() {

    },

    methods: {
        keyPress(key) {
            if (key === "Backspace") {
                this.input = this.input.slice(0, -1);
            } else if (key === "Space") {
                this.input += " ";
            } else {
                this.input += key;
            }
        },
        t9KeyPress(key) {
            const t9 = {
                "1": ["0", "1", " "],
                "2": ["2", "A", "B", "C"],
                "3": ["3", "D", "E", "F"],
                "4": ["4", "G", "H", "I"],
                "5": ["5", "J", "K", "L"],
                "6": ["6", "M", "N", "O"],
                "7": ["7", "P", "Q", "R", "S"],
                "8": ["8", "T", "U", "V"],
                "9": ["9", "W", "X", "Y", "Z"]
            };

            const currentCharIndex = t9[key].indexOf(this.input.slice(-1));
            const nextChar = t9[key][(currentCharIndex + 1) % t9[key].length];
            const currentTime = new Date().getTime();
            if (this.lastKeyPressTime && currentTime - this.lastKeyPressTime < 1000 && this.lastKey === key) {
                this.input = this.input.slice(0, -1) + nextChar;
            } else {
                this.lastKey = key;
                this.input += t9[key][0];
            }

            this.lastKeyPressTime = currentTime;
        },
        // 清除全部输入内容
        tapClear() {
            this.input = ''
        }
    }
}
</script>


<style lang="less" scoped>
@import './css/search.less';
</style>